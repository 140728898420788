import { Form, Switch, SwitchProps, Typography } from 'antd';
import { useField } from 'formik';
import { FC } from 'react';
import { Stack } from '../Stack';
interface Props extends SwitchProps {
  fieldName: string;
  label?: string;
}

export const SwitchInputIsActive: FC<Props> = ({ fieldName, label }) => {
  const [{ value }, { touched, error }, { setValue }] = useField<boolean>(fieldName);

  return (
    <Form.Item validateStatus={touched && error ? 'error' : 'success'} help={touched && error ? error : undefined}>
      <Stack flexDirection="column" alignItems="flex-start">
        {!!label && <Typography.Text>{label}</Typography.Text>}
        <Switch checked={value} onChange={(checked): void => setValue(checked)} />
      </Stack>
    </Form.Item>
  );
};
