import { Col, Form, Row } from 'antd';
import { SwitchInputIsActive, TextInput } from 'components/UI/FormItems';
import { FormRadio } from 'components/UI/FormItems/Radio';

export const CreateApplicationForm = (): JSX.Element => {
  // Hooks

  // Render
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <TextInput fieldName="name" label="Name" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="accessPermissionName" label="Access Permission Name" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="description" label="Description" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="url" label="URL" />
        </Col>
        <Col span={12}>
          <SwitchInputIsActive fieldName="isActive" label="Is Active" />
        </Col>
        <Col span={24}>
          <FormRadio fieldName="isDivisionContextRequired" label="Please pick the type of application you are creating" />
        </Col>
      </Row>
    </Form>
  );
};
